import { safe_env_get } from "@/lib/util/env";

export const isProd = process.env["NODE_ENV"] == "production";

export const SENTRY_ENVIRONMENT =
  safe_env_get("SENTRY_ENVIRONMENT", "develop") === "production";

export const SESSION_COOKIE_NAME = "aiea-platform-session";

export const BCRYPT_SALT_ROUNDS = parseInt(
  safe_env_get("BCRYPT_SALT_ROUNDS", "10")
);

export const MONGO_CONNECTION_STRING = safe_env_get(
  "MONGO_CONNECTION_STRING",
  "mongodb://localhost:27017/aiea-platform"
);

export const JWT_PRIVATE_KEY_PATH = safe_env_get(
  "JWT_PRIVATE_KEY_PATH",
  // path.join(__dirname, "../../../../lib/src/private.pem")
  "/home/robertgarden/dev/merlynn/aiea/lib/src/private.pem"
);

export const SECRET = safe_env_get("SECRET", "some-secret");

export const SENDGRID_API_KEY = safe_env_get("SENDGRID_API_KEY", "");

export const EMAIL_FROM = safe_env_get(
  "EMAIL_FROM",
  "aiea-platform@up2tom.com"
);

export const DOMAIN = safe_env_get("DOMAIN", "http://localhost:4000");

export const UP2TOM_STORAGE_BUCKET_URL = safe_env_get(
  "UP2TOM_STORAGE_BUCKET_URL",
  // "https://storage.googleapis.com/up2tom-assets"
  "https://cdn.up2tom.com"
);

export const STATIC_MODELS = safe_env_get("STATIC_MODELS", "").split(",");

export const UP2TOM_API_HOST = safe_env_get(
  "UP2TOM_API_HOST",
  "https://api.up2tom.com"
);

export const AIEA_SUPPORT_MAILBOX = safe_env_get(
  "AIEA_SUPPORT_MAILBOX",
  "dev.support@aiemploymentagency.com"
);

export const AIEA_ADMIN_DOMAIN = safe_env_get("AIEA_ADMIN_DOMAIN", "");

export const AIEA_GUEST_DOMAIN = safe_env_get(
  "AIEA_GUEST_DOMAIN",
  "https://guest.aiemploymentagency.com"
);

export const DASHBOARD_ACCESS_TOKEN_EXPIRY = "3 days";
