import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AccountCreationState {
  email?: string;
  password?: string;
  token?: string;
}

const initialState: AccountCreationState = {};

export const accountCreationSlice = createSlice({
  name: "accountCreation",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAccountCreationState: (
      state,
      action: PayloadAction<AccountCreationState>
    ) => {
      return action.payload;
    },
    reset: (state) => {
      return {};
    },
  },
});

export const { setAccountCreationState, reset } = accountCreationSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default accountCreationSlice.reducer;
