import * as React from "react";
import { GetServerSideProps } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { Toaster } from "@/ui/toast";
import { SENTRY_ENVIRONMENT } from "@/app/config";
import { CookieConsentPopover } from "@/components/CookieConsent";
import { store } from "app/store";
import { QueryClientProvider, QueryClient } from "react-query";
import { Provider } from "react-redux";
import "styles/globals.css";
import "styles/react-day-picker/style.css";

const queryClient = new QueryClient();

export const getServerSideProps: GetServerSideProps = async () => {
  return { props: { SENTRY_ENVIRONMENT: SENTRY_ENVIRONMENT } };
};

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>AI Employment Agency</title>
        {SENTRY_ENVIRONMENT ? (
          <link rel="shortcut icon" href="/favicon.png" />
        ) : (
          <link rel="shortcut icon" href="/AIEA_Admin Favicon 96X96px-11.png" />
        )}

        <meta
          name="insight-app-sec-validation"
          content="5ff7266c-f29b-4b20-8875-20935bd7edf4"
        />
        <meta
          httpEquiv="Content-Security-Policy"
          content="default-src * https://cdn.up2tom.com https://api.up2tom.com 'unsafe-inline'; script-src 'self' www.google-analytics.com https://browser.sentry-cdn.com https://js.sentry-cdn.com https://*.sentry.io https://*.ingest.sentry.io https://cdn.up2tom.com https://api.up2tom.com 'unsafe-inline'; connect-src * https://cdn.up2tom.com https://api.up2tom.com sentry.io *.sentry.io https://*.ingest.sentry.io; style-src 'self' https://fonts.googleapis.com 'unsafe-inline'; font-src 'self' https://fonts.gstatic.com; base-uri 'self'; img-src * data:"
        />
        <meta
          httpEquiv="Content-Type"
          content="text/html; charset=UTF-8; X-Content-Type-Options=nosniff"
        />
      </Head>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <React.StrictMode>
            <Component {...pageProps} />
            <Toaster />
            <CookieConsentPopover />
          </React.StrictMode>
        </QueryClientProvider>
      </Provider>
    </>
  );
}

export default App;
