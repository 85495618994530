import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ChatState {
  chatWindowVisible: boolean;
}

// Define the initial state using that type
const initialState: ChatState = {
  chatWindowVisible: false,
};

export const chatSlice = createSlice({
  name: "chat",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    showChatWindow: (state) => {
      state.chatWindowVisible = true;
    },
    hideChatWindow: (state) => {
      state.chatWindowVisible = false;
    },
    toggleChatWindow: (state) => {
      state.chatWindowVisible = !state.chatWindowVisible;
    },
  },
});

export const { showChatWindow, hideChatWindow, toggleChatWindow } =
  chatSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value;

export default chatSlice.reducer;
