import { ModelWrapper } from "./ModelWrapper";
import { User } from "./User";
import { Schema } from "mongoose";
import { Setup } from "./Setup";

export type DealTypeExplicit = "Internal" | "Outgoing" | "Incoming";

export enum DealStatus {
  Requested = "Requested",
  Approved = "Approved",
  Disabled = "Disabled",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export enum DealPaymentPeriod {
  Weekly = "Weekly",
  Biweekly = "Biweekly",
  Monthly = "Monthly",
  Quarterly = "Quarterly",
  Annually = "Annually",
}

export enum DealType {
  Internal = "Internal",
  External = "External",
}

export interface ExtraDealsData {
  model: ModelWrapper[];
  expert: User[];
  requester: User[];
}
export interface ExtendedDeals extends Deals, ExtraDealsData {}

export interface Deals {
  _id: string;
  model_id: ModelWrapper;
  deal_type: DealType;
  setup: Setup;
  payment_periods: DealPaymentPeriod;
  creator: {
    creator_id: User;
    creator_contact_number: string;
    creator_email_address: string;
  };
  consumer: {
    consumer_id: User;
    consumer_contact_number: string;
    consumer_email_address: string;
    consumer_alternative_email_address: string;
  };
  admin: {
    admin_name: string;
    admin_email_address: string;
  };
  status: DealStatus;
  revenue_stream: [
    {
      revenueStream: {
        type: Schema.Types.ObjectId;
        ref: "revenueStreams";
        //required: true,
      };
      percentage: number;
    },
  ];
  prepaid_amounts: {
    prepaid_decision_amount: number;
    prepaid_transition_cost: number;
  };
  pricing_scale: {
    bucket_one: {
      min: number;
      max: number;
      price: number;
    };
    bucket_two: {
      min: number;
      max: number;
      price: number;
    };
    bucket_three: {
      min: number;
      max: number;
      price: number;
    };
    bucket_four: {
      min: number;
      max: number;
      price: number;
    };
    bucket_five: {
      min: number;
      price: number;
    };
  };
  limits: {
    enforce_hard_limit: string;
    hard_limit_amount: number;
    soft_limit_amount: number;
  };
  createdAt: string;
  updatedAt: string;
}
