import { FC } from "react";
import { cn } from "@/lib/util/cn";
import { Loader2, LucideProps } from "lucide-react";

export const LoadingSpinner: FC<LucideProps> = ({ className, ...props }) => {
  return (
    <Loader2
      className={cn("flex-shrink-none h-5 w-5 animate-spin text-black", className)}
      {...props}
    />
  );
};
