import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
} from "@aiea/ui";

const cookies = [
  {
    name: "essential",
    label: "Essential",
    description:
      "Essential cookies are required for the operation of our website, for example, cookies that enable you to log into secure areas of our website.",
  },
  {
    name: "analytics",
    label: "Analytics",
    description:
      "We use cookies to analyze how our visitors use our website and to monitor website performance.",
  },
] as const;
const STORAGE_KEY = "cookiePreference";
const getCookiePreference = () => localStorage.getItem(STORAGE_KEY);

export const CookieConsentPopover: FC = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [cookiePreferenceState, setCookiePreferenceState] = useState({
    essential: true,
    analytics: false,
  });
  const router = useRouter();

  useEffect(() => {
    if (router.pathname.includes("/login")) {
      return;
    }

    if (getCookiePreference() === null) {
      setIsPopoverOpen(true);
      return;
    }
  }, [router.pathname]);

  const handleSave = (pref: { essential: boolean; analytics: boolean }) => {
    const cookiePreference = getCookiePreference();
    if (cookiePreference === null) {
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({
          preference: pref,
        }),
      );
    }
    setIsPopoverOpen(false);
  };

  return (
    <Popover open={isPopoverOpen}>
      <PopoverTrigger hidden>WTF</PopoverTrigger>
      <PopoverContent asChild className="relative">
        <div className="relative h-screen w-[0rem] border-none bg-transparent">
          <Card className="absolute bottom-0 mb-10 ml-6 w-[30rem] p-0">
            <CardHeader>
              <CardTitle>Can we use cookies?</CardTitle>
              <CardDescription>
                We use cookies to improve user experience, and analyze website
                traffic. Please click{" "}
                <a
                  href="https://www.merlynn-ai.com/governancepolicies"
                  className="underline"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </a>{" "}
                to learn more about our cookies.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="flex flex-col gap-4">
                {cookies.map((cookie) => (
                  <li
                    key={cookie.name}
                    className="flex items-center justify-between gap-x-4"
                  >
                    <label htmlFor={cookie.name} className="flex flex-col">
                      <span className="text-sm font-bold">{cookie.label}</span>
                      <span className="mt-2 text-sm">{cookie.description}</span>
                    </label>
                    <Switch
                      id={cookie.name}
                      checked={cookiePreferenceState[cookie.name]}
                      onCheckedChange={(checked) => {
                        setCookiePreferenceState((prevState) => ({
                          ...prevState,
                          [cookie.name]: checked,
                        }));
                      }}
                    />
                  </li>
                ))}
              </ul>
            </CardContent>
            <CardFooter className="flex flex-col gap-2">
              <div className="flex w-full gap-2">
                <Button
                  className="w-full flex-1"
                  variant="outline"
                  onClick={() => {
                    handleSave({
                      essential: false,
                      analytics: false,
                    });
                  }}
                >
                  Reject all
                </Button>
                <Button
                  className="w-full flex-1"
                  variant="outline"
                  onClick={() => {
                    handleSave({
                      essential: true,
                      analytics: true,
                    });
                  }}
                >
                  Accept all
                </Button>
              </div>
              <Button
                className="w-full"
                onClick={() => handleSave(cookiePreferenceState)}
              >
                Save preference
              </Button>
            </CardFooter>
          </Card>
        </div>
      </PopoverContent>
    </Popover>
  );
};
